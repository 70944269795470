/**
 * Author: ReeooShen
 * WuKong JS SDK
 * Date: 2018/04/10
 * Version: 1.0.0
 * Doc：http://fed.tcent.cn/public/rulai/wukong/wk.html
 * http://js.40017.cn/fed/wukong/wukong.1.0.4.js
 * http://js.t.40017.cn/fed/wukong/wukong.1.0.4.js
 */

const TIMESTAMP = +new Date();
const ua = navigator.userAgent.toLowerCase();
const isTC = ua.indexOf("tctravel") !== -1;
const Promise = require('promise-polyfill').default;

let clientInfo = {};

const DATA_MAPS = {
    traceType: "t",
    moduleId: "mid",
    rootPageName: "pn",
    rootPageCode: "rc",
    subPageName: "sn",
    subPageCode: "sc",
    traceId: "tid",
    actionName: "act",
    actionCode: "ac",
    extra: "extra",
    ext: "ext"
}

/**
 * 提交的数据类型：page:页面数据，event:事件
 * @type {{PAGE: string, EVENT: string}}
 */
const DATA_TYPES = {
    PAGE: "page",
    EVENT: "event"
}

require("rpush-fe");

let push = window.RPush;

const UA = window.navigator.userAgent;

function generateWs() {

    if (this.ws) return;

    let me = this;

    me.ws = new push("wss://flights.17u.cn/buddha/transmit-proxy/ws?chn=" + (me.config.chn || "wechat"), {protocols: []})
}

/**
 * 重写方法
 * @param obj
 * @param name
 * @param replacement
 */
function rewrite(obj, name, replacement) {
    if (!obj) return;
    let orig = obj[name];
    obj[name] = replacement(orig);
    obj[name].wk_wrapped = true;//是否已经修改过
    obj[name]._orig_ = orig;//原始方法
}

/**
 * 处理值
 * @param val
 * @returns {*}
 */
function processVal(target) {
    // ts:页面停留时间戳或事件间隔
    // t:"event",
    // pid:"项目Id",
    // mid:"组件ID",
    // pn:"book1",//页面名称
    // rc:"1",//根页面code rc = root code
    // sn:"日历",//子页面名称
    // sc:"1_2",//子页面code  sc = sub code
    // act:"点击|加载完成|选择",//行为
    // ac:"1_1_1"//行为code ac = action code
    // extra:"PM埋点数据"
    // ext:"可扩展数据"
    try {
        let data = {
            mid: target.getAttribute("moduleId") || "",
            pn: target.getAttribute("rootPageName") || "",
            rc: target.getAttribute("rootPageCode") || "",
            sn: target.getAttribute("subPageName") || "",
            sc: target.getAttribute("subPageCode") || "",
            act: target.getAttribute("actionName") || "",
            ac: target.getAttribute("actionCode") || "",
            extra: target.getAttribute("extra") || "",
            ext: target.getAttribute("extend") || "",
        }

        let isDataValid = true;
        if (isDataValid) {
            return data;
        }
        return false;
    }
    catch (ex) {
        let wukongDebug = parseQs("_wukong_debug_") || "";
        if (wukongDebug) {
            window.console && console.log(ex);
        }
    }
}

function processPageAuto(me) {

    if (me.loadFire) {
        me.loadFire = false;
        return;
    }

    if (me.changed) return;

    me.changed = true;

    if (me.config.pageAuto) {
        me.baseProps = setBaseProps.call(me);
        let pageTracerId = me.config.pageTracerId;
        let pageTracer = document.getElementById(pageTracerId);
        if (pageTracer) {
            let data = processVal.call(me, pageTracer);
            if (data) {
                data.traceType = "page";
                send.call(me, data);
            }
        }
    }

    if (me.config.chn === "app" && isTC) {//合并app设备信息、会员信息等，特殊处理
        //获取客户端信息
        getAppBaseInfo.call(me);
    }
    else {
        send.call(me, merge({
            ts: +new Date()
        }, me.baseProps));

    }

    setTimeout(function () {
        me.changed = false;
    }, 300)

}

/**
 * 劫持History
 */
/**
 * 生成UUID
 * @param format 格式化
 * @returns {string}
 */
function uuid(format) {
    let d = new Date().getTime();

    return (format || 'xxxxxxxx-xxxx-xxxx-yxxx-xxxxxxxxxxxx').replace(/[xy]/g, function(c) {
        let r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    })
}

function rewriteHistory() {
    let me = this;

    function rewritePS(type) {
        let orig = history[type];
        history[type] = function () {

            sessionStorage.setItem('dk_r', uuid());

            processPageAuto(me);
            return orig.apply(history, arguments);
        };
    }

    rewritePS("pushState");
    rewritePS("replaceState");

}

// function rewriteHistory() {
//     let me = this;
//
//     function rewritePS(type) {
//         let orig = history[type];
//         return function () {
//             let res = orig.apply(this, arguments);
//             let event = new Event(type);
//             event.arguments = arguments;
//             window.dispatchEvent && window.dispatchEvent(event);
//             return res;
//         };
//     }
//
//     history.pushState = rewritePS('pushState');
//     history.replaceState = rewritePS('replaceState');
//
//     window.addEventListener("replaceState", function () {
//         console.log(111)
//     }, false)
//
//
//     window.addEventListener("pushState", function () {
//         console.log(222)
//     }, false)
//
// }

/**
 * 包裹方法
 * @param fn
 * @returns {*}
 */
function wrap(fn, eventName) {

    let me = this;
    //已经修改过的方法，直接返回
    if (fn.wk_wrapped) return fn;

    if (fn.wk_wrapper) {
        return fn.wk_wrapper;//返回修改过的方法
    }

    function wrapper(e) {

        if (eventName === "click") {
            let target = e.target || "";
            if (target) {
                let attrName = me.config.attrName;
                // todo IE8 & lower 不支持hasAttribute 方法
                if (target.hasAttribute && target.hasAttribute(attrName)) {
                    let res = processVal.call(me, target);
                    if (res) {
                        res.traceType = "event";
                        send.call(me, res);
                    }
                }
            }
        }

        let res = fn.apply(this, arguments);

        if (eventName === "popstate" || eventName === "hashchange") {//todo

            processPageAuto(me);

        }

        return res;
    }

    // 拷贝用户函数的属性
    for (let prop in fn) {
        if (Object.prototype.hasOwnProperty.call(fn, prop)) {
            wrapper[prop] = fn[prop];
        }
    }

    wrapper.prototype = fn.prototype;

    fn.wk_wrapper = wrapper;

    wrapper.wk_wrapped = true;
    wrapper._orig_ = fn;

    return wrapper;
}

/**
 * 重写add|removeEventListener
 */
function rewriteListener() {
    let me = this;

    if (window.EventTarget && window.EventTarget.prototype) {
        rewrite(window.EventTarget.prototype, 'addEventListener', function (orig) {
            return function (evtName, fn, capture) {
                if (evtName === "click" || evtName === 'popstate' || evtName === 'hashchange') {
                    return orig.call(this, evtName, wrap.call(me, fn, evtName), capture);
                } else {
                    return orig.call(this, evtName, fn, capture);
                }
            };
        });
        rewrite(window.EventTarget.prototype, 'removeEventListener', function (orig) {
            return function (evtName, fn, capture) {
                try {
                    fn = fn && (fn.wk_wrapper ? fn.wk_wrapper : fn);
                } catch (e) {
                    //某些环境下可能会报错
                }
                return orig.call(this, evtName, fn, capture);
            };
        });
        return true;
    }
    else {
        return false;
    }
}

/**
 * 获取sessionId
 * @returns {string}
 */
function getSessionId() {

    let oid = getOpenId();
    try {
        let sessionId = window.sessionStorage.getItem("_air_session_id_");
        if (sessionId) return sessionId;
        if (this.config.chn === "app" && isTC) {//app渠道，sessionId用memberId+时间戳的格式生成，如果没有memberId则使用八位随机数代替
            let memberId = clientInfo.md || Math.floor(Math.random() * 10e7);
            sessionId = memberId + "-" + TIMESTAMP;
        }
        else {
            sessionId = (oid ? oid : 0) + "-" + TIMESTAMP;
        }

        window.sessionStorage.setItem("_air_session_id_", sessionId);
        return sessionId;
    }
    catch (e) {
        return "";
    }
}

/**
 * 获取客户端信息
 */
function getClientInfo() {
    return new Promise((resolve, reject) => {
        window._tc_bridge_user &&
        window._tc_bridge_user.get_device_info &&
        window._tc_bridge_user.get_device_info({
            param: {},
            callback: function (data) {
                try {
                    let res = JSON.parse(data.CBData),
                        deviceInfo = res.deviceInfo || {},
                        memberInfo = res.memberInfo || {};
                    clientInfo.mo = memberInfo.mobile || "";
                    clientInfo.gd = memberInfo.sex || ""
                    clientInfo.ver = deviceInfo.appVersionNumber || "";
                    clientInfo.dn = deviceInfo.deviceName || "";
                    clientInfo.did = deviceInfo.deviceId || "";
                    clientInfo.md = memberInfo.memberId || "";
                    clientInfo.os = deviceInfo.appVersionType || "";
                    if (deviceInfo.lat && deviceInfo.lon) {
                        clientInfo.lat = deviceInfo.lat;//since 835
                        clientInfo.lon = deviceInfo.lon;//since 835
                    }
                    resolve({...clientInfo})
                }
                catch (e) {
                    reject({})
                }
            }
        })
    })
}

/**
 * 获取网络状态
 */
function getNetworkType() {
    return new Promise((resolve, reject) => {
        window._tc_bridge_util &&
        window._tc_bridge_util.get_network_type &&
        window._tc_bridge_util.get_network_type({
            param: {},
            callback: function (data) {
                try {
                    let res = JSON.parse(data.CBData);
                    clientInfo.nt = res.networkType || "";
                    resolve({...clientInfo})
                }
                catch (e) {
                    reject({})
                }
            }
        })
    })
}

function getAppBaseInfo() {
    let me = this;

    Promise.all([getClientInfo(), getNetworkType()]).then((data) => {
        let res = merge(data[0], data[1]);

        me.baseProps = merge(me.baseProps, res);
        let memberId = me.baseProps.md || "";
        if (memberId) {
            me.baseProps.sid = me.baseProps.sid.replace(/(.*)\-/gi, memberId + "-");
        }
        try {
            send.call(me, merge({//发送基础数据
                ts: +new Date()//发送时间戳
            }, me.baseProps));
        }
        catch (e) {

        }
    })
}

/**
 * 初始化获取基础数据
 * @returns {{oid: (*|string), sid: (*|string), uid: *, srw: Number, srh: Number,rid: (*|boolean|number), tid: *}}
 */
function setBaseProps() {
    let me = this;
    return {
        chn: this.config.chn || "wechat",
        oid: getOpenId(),//openId
        r: document.referrer || "",
        ua: UA,
        ve: "1.1.5.190813",
        sid: getSessionId.call(me),//sessionId
        uid: getUnionId(),//unionId
        srw: screen.width,//屏幕宽度
        srh: screen.height,//屏幕高度
        rid: getRefId.call(me),//refId
        tid: getTraceId(),//tid
        pid: me.config.projectId || ""
    };
}

function transformData(data) {
    let res = {};
    for (let item in data) {
        if (data.hasOwnProperty(item)) {
            if (DATA_MAPS[item]) {
                res[DATA_MAPS[item]] = data[item];
            }
            else {
                res[item] = data[item];
            }
        }
    }

    if (isObjEmpty(res)) return data;

    return res;
}

/**
 * 检测chn是否合法
 * @returns {boolean}
 */
function isChnValid(chn) {
    // if (["app", "wechat", "xcx"].indexOf(chn) === -1) return false;
    return true;
}


/**
 * 发送数据 onload和popstate发送基础数据，其他情况只发送用户数据，两种数据通过sessionId关联
 * @param ac action code 行为code String
 * @param data Object<>
 */
function send(data) {
    try {
        let chn = this.config.chn,
            projectId = this.config.projectId;


        if (!isChnValid(chn)) return false;

        if (!data || !isObjByType(data) || isObjEmpty(data)) {
            console.log("data type invalid or data missing!");
            return false;
        }

        let rootPageName = data.rootPageName || "",
            subPageName = data.subPageName || "",
            actionName = data.actionName || "",
            extra = data.extra || "",
            category = `${chn}_${projectId}`,
            action = `${rootPageName}_${subPageName}`,
            label = actionName,
            value = extra;

        data = transformData(data);

        data.ts = +new Date();//事件或者页面数据时间戳


        if (!data.sid) {//通过sessionId关联
            data.sid = this.baseProps.sid || "";
        }

        data.dk_r = sessionStorage.getItem('dk_r');  //添加页面停留时间内的uuid

        let wukongDebug = parseQs("_wukong_debug_") || "";

        if (wukongDebug) {
            window.console && console.table && console.table(data);
        }

        data = JSON.stringify(data);//转成字符串，传给ws

        if (chn !== "app") {
            this.ws && this.ws.send(data);//直接发送，不管他ws什么状态
        }
        else {
            if (isTC) {
                this.ws && this.ws.send(data);//直接发送，不管他ws什么状态
            }
        }
    }
    catch (e) {
        let wukongDebug = parseQs("_wukong_debug_") || "";
        if (wukongDebug) {
            window.console && console.log(e);
        }
    }
}

/**
 * 埋点触发页面数据收集
 * 结合国际机票和国内机票的H5页面来看，目前用到的page发送的数据只有两个参数，一个当前页面的名称，另一个是来源页面的名称，后续如果有其他参数的需求可以再增加
 * @param  String pg    当前页面名称
 * @param  String orgpg 来源页面名称
 * @return {[type]}       [description]
 */
function pageTrack(pg, orgpg) {
    if (typeof _tcTraObj === "undefined") {
        setTimeout(function () {
            if (typeof _tcTraObj !== "undefined") {
                _tcTraObj._tcTrackPage(pg, orgpg);
            }
        }, 1000)
    } else {
        _tcTraObj._tcTrackPage(pg, orgpg);
    }
}

/**
 * 事件跟踪方法
 * @param  String category  类别
 * @param  String action    操作
 * @param  String opt_label 标签
 * @param  String opt_value 值
 * @return {[type]}           [description]
 */
function eventTrack(category, action, opt_label, opt_value) {
    if (typeof _tcTraObj === "undefined") {
        setTimeout(function () {
            if (typeof _tcTraObj !== "undefined") {
                _tcTraObj._tcTrackEvent(category, action, opt_label, opt_value)
            }
        }, 1000)
    } else {
        _tcTraObj._tcTrackEvent(category, action, opt_label, opt_value)
    }
}


/**
 * 判断给定的参数是什么类型的
 * @param o
 * @param type
 * @returns {boolean}
 */
function isObjByType(o, type) {
    return Object.prototype.toString.call(o) === "[object " + (type || "Object") + "]";
}

/**
 * 获取refId
 * @returns {*|boolean|number}
 */
function getRefId() {
    let me = this;
    let refId = parseQs("refid");//从URL上读取refid
    refId = refId || getCookies("CNSEInfo", "RefId") || "";
    if (me.config.projectId) {
        switch (me.config.projectId) {
            case "1"://国内机票
                refId = refId || "36849990";//默认值
                break;
            case "2"://国际机票
                break;
            case "3"://商旅
                break;
        }
    }

    return refId;
}

/**
 * 合并对象
 * @param source
 * @param dest
 * @returns {*}
 */
function merge(source, dest) {

    let sourceCopy = JSON.parse(JSON.stringify(source))
    let destCopy = JSON.parse(JSON.stringify(dest))

    for (let item in destCopy) {
        if (destCopy.hasOwnProperty(item) && destCopy[item]) {
            sourceCopy[item] = destCopy[item];
        }
    }
    return sourceCopy;
}

/**
 * 判断对象是否为空对象
 * @param obj
 * @returns {boolean}
 */
function isObjEmpty(obj) {
    let res = true;
    if (isObjByType(obj)) {
        for (let o in obj) {
            if (obj.hasOwnProperty(o)) {
                res = false;
                break;
            }
        }
    }
    return res;
}

/**
 * 获取openId
 * @returns {*|string}
 */
function getOpenId() {
    let oid = parseQs("openid") || getCookies("WxUser", "openid") || getCookies("cookieOpenSource", "openid") || "";
    return oid
}

/**
 * 通过cookie key取cookie值
 * @param keyVal
 * @returns {*}
 */
function getCookieKey(keyVal) {
    let arr,
        reg = new RegExp("(^| )" + keyVal + "=([^;]*)(;|$)");
    if (arr = document.cookie.match(reg))
        return arr[2];
    else
        return null;
}

/**
 * 通过key值和val值获取cookie值
 * @param keyVal String cookie key
 * @param val String cookie值，如果值是嵌套的，可以自动解析嵌套cookie
 * @returns {*}
 */
function getCookies(keyVal, val) {
    let cookieVal = getCookieKey(keyVal),
        arr = [],
        valTemp;
    cookieVal = cookieVal && cookieVal.replace(/\s/g, "") || "";
    arr = cookieVal && cookieVal.split("&") || [];
    if (val == null || val == "") {
        if (arr.length == 1) {
            valTemp = cookieVal
        }
    } else {
        if (arr.length > 0) {
            for (let i = 0, lens = arr.length; i < lens; i++) {
                if (arr[i].split("=")[0] == val) {
                    valTemp = arr[i].replace(/=/, "%C%C%").split("%C%C%")[1];
                }
            }
        }
    }
    return valTemp
}

/**
 * 获取UnionID
 * @returns {*|string}
 */
function getUnionId() {
    let uid = parseQs("unionid") || getCookies("WxUser", "unionid") || "";
    return uid
}

/**
 * 获取url参数中的key值
 * @param key
 * @returns {*}
 */
function parseQs(key) {
    let qs = location.search.length > 0 ? location.search.substring(1) : '';
    let items = qs.length ? qs.split('&') : [],
        item,
        name,
        value,
        args = {},
        len = items.length;

    for (let i = 0; i < len; i++) {
        item = items[i].split('=');
        name = decodeURIComponent(item[0]);
        value = item[1] && decodeURIComponent(item[1]) || "";
        if (name.length) {
            args[name] = value;
        }
    }

    if (key) {
        return args[key] || null;
    }

    return args;
}

/**
 * 获取traceId
 * @returns {string}
 */
function getTraceId() {
    let traceid = "";
    let urlTraceId = parseQs("traceid");
    if (urlTraceId) {
        traceid = urlTraceId;
    } else {
        if (getCookies("traceid")) {
            traceid = getCookies("traceid");
        }
        //todo cookie取不到的话，要等待后端生成之后重新获取cookie中的traceid，然后返回
    }
    return traceid
}

module.exports = {
    isObjByType,
    isObjEmpty,
    merge,
    setBaseProps,
    rewriteListener,
    send,
    processVal,
    rewriteHistory,
    generateWs,
    clientInfo,
    getAppBaseInfo,
    TIMESTAMP,
    isTC,
    pageTrack,
    eventTrack,
    uuid
}