/**
 * Author: ReeooShen
 * WuKong JS SDK
 * Date: 2018/04/10
 * Version: 1.0.0
 * Doc：http://fed.tcent.cn/public/rulai/wukong/wk.html
 * http://js.40017.cn/fed/wukong/wukong.1.0.2.js
 * http://js.t.40017.cn/fed/wukong/wukong.1.0.2.js
 */

let {
    setBaseProps,
    isTC,
    getAppBaseInfo,
    send,
    rewriteListener,
    merge,
    isObjEmpty,
    isObjByType,
    processVal,
    rewriteHistory,
    generateWs,
    TIMESTAMP,
    pageTrack,
    eventTrack,
    uuid
} = require("./utils")


/**
 *
 * @param options
 * @returns {WuKongJS}
 * @constructor
 */
function WuKongJS(options) {
    if (!(this instanceof WuKongJS)) return new WuKongJS(options);
    this.called = false;
    this.changed = false;
    this.channel = "Wechat";
    this.loadFire = true;
    this.baseProps = null;//基础数据
    this.defaultSettings = {
        attrName: "wk_event_tracer",//属性名称
        pageAuto: true,//是否在页面加载完成的时候自动发送数据 监听onload，如果觉得监听时机有问题，可以禁用，然后自己手动监听需要的生命周期。
        pageTracerId: "wk_page_tracer",
        chn: "wechat",//渠道，目前的值只有一个，wechat
    }
    this.config = merge(this.defaultSettings, options);
    if (!this.config.projectId) {
        console.log("projectId missing!")
        return;
    }

    this.init(options);
}

/**
 * 悟空SDK原型方法
 * @type {{constructor: WuKongJS, init: (())}}
 */
WuKongJS.prototype = {
    constructor: WuKongJS,
    /*
     * {
     *     projectId:1,//国内机票：1，国际机票：2，商旅：3
     *     type : "event|page",//提交类型，页面还是事件
     *     moduleId : "推荐模块"，//组件ID
     *     rootPageName : "book1",//页面名称
     *     rootPageCode : "1",//根页面code rc = root code
     *     subPageName : "日历",//子页面名称
     *     subPageCode : "1_2",//子页面code  sc = sub code
     *     actionName : "点击|加载完成|选择",//行为
     *     actionCode : "1_1_1"//行为code ac = action code
     *     extra : "pm埋点数据，比如：低价日历数据准确度^depCity:北京^arrCity:上海^srDate:2018-04-01^depDate:2018-04-01^lp:/^lpCl:550^matchRt:2^"
     *     extend: 扩展参数
     * }
     * */
    init() {
        if (this.called) return false;
        let me = this;
        this.called = true;
        // console.log(`memberId:${clientInfo.md}`);
        this.baseProps = setBaseProps.call(this);

        generateWs.call(this);
        let eventTargetSupport = rewriteListener.call(this);//劫持addEventListener
        if (!eventTargetSupport) {
            console.log("eventTarget not support!")
        }
        rewriteHistory.call(me);//劫持History

        window.addEventListener("load", function () {

            sessionStorage.setItem('dk_r', uuid());

            if (me.config.pageAuto) {
                let pageTracerId = me.config.pageTracerId;
                if (pageTracerId) {
                    let pageTracer = document.getElementById(pageTracerId);
                    if (pageTracer) {
                        let data = processVal.call(me, pageTracer);
                        if (data) {
                            data.traceType = "page";
                            send.call(me, data);
                        }
                    }
                }
            }
            if (me.config.chn === "app" && isTC) {//合并app设备信息、会员信息等，特殊处理
                //异步获取客户端信息

                getAppBaseInfo.call(me);
            }
            else {
                send.call(me, merge({//发送基础数据
                    ts: +new Date()//发送时间戳
                }, me.baseProps));
            }
        }, false)
    },
    sendPage(data) {
        if (!data || !isObjByType(data) || isObjEmpty(data)) {
            console.log("data type invalid or data missing!");
            return false;
        }
        data.traceType = "page";
        return send.call(this, data);
    },
    /**
     * 页面埋点数据，直接扔给市场中心
     */
    sendPage2(data) {
        let chn = this.config.chn,
            rootPageName = data.rootPageName || "",
            subPageName = data.subPageName || "",
            orgpagename = data.orgPageName || "",
            pageName = `${chn}_${rootPageName}_${subPageName}`;

        if (this.config.chn === "app" && isTC) {
            window._tc_bridge_util &&
            window._tc_bridge_util.set_page &&
            window._tc_bridge_util.set_page({
                "param": {
                    "pagename": pageName
                }
            })
        }
        else {
            pageTrack(pageName, orgpagename)
        }


    },
    sendEvent(data) {
        if (!data || !isObjByType(data) || isObjEmpty(data)) {
            console.log("data type invalid or data missing!");
            return false;
        }

        data.oid = this.baseProps.oid || "";
        data.uid = this.baseProps.uid || "";
        data.pid = this.baseProps.pid || "";

        data.traceType = "event";
        console.log(data)
        return send.call(this, data);
    },
    sendEvent2(data) {
        if (data.only) {
            return this.sendEvent(data);//悟空事件埋点
        }

        this.sendEvent(data);//悟空事件埋点

        let chn = this.config.chn,
            projectId = this.config.projectId,
            rootPageName = data.rootPageName || "",
            subPageName = data.subPageName || "",
            actionName = data.actionName || "",
            extra = data.extra || "",
            category = `${chn}_${projectId}`,
            action = `${rootPageName}_${subPageName}`;

        if (this.config.chn === "app" && isTC) {
            window._tc_bridge_util &&
            window._tc_bridge_util.set_category_event &&
            window._tc_bridge_util.set_category_event({
                "param": {
                    "category": category,
                    "action": action,
                    "optLabel": actionName,
                    "optValue": extra,
                    "pagename": action
                }
            })
        }
        else {
            eventTrack(category, action, actionName, extra);
        }

    },
    /*
    *
    * ht_r:"页面开始时间（时间戳），人机识别使用",
    * st_r:"页面关闭时间（时间戳），人机识别使用"
    * mc_r:move count 用户滑动页面次数，人机识别使用
    * tc_r:tap count 用户点击页面次数，人机识别使用
    * dk_r: data key 人机识别使用，每进一个页面都重新生成，但是当前页面的数据，这个ID都是一样的
    * */
    sendEventCount(data) {
        this.sendEvent(data);//页面停留时间和滑动，点击事件次数
    },
    getSessionId(params) {//userId:微信中为openId，app内为memberId
        try {
            if (this.baseProps.oid && this.baseProps.sid) return this.baseProps.sid;
            if (params && params.memberId) {
                this.baseProps.md = params.memberId;
                this.baseProps.sid = this.baseProps.md + "-" + TIMESTAMP;
            }
            else if (params && params.openId) {
                this.baseProps.oid = params.openId;
                this.baseProps.sid = this.baseProps.oid + "-" + TIMESTAMP;
            }
            return this.baseProps.sid || "";
        }
        catch (e) {

        }
    }
}

module.exports = {
    WuKongJS
}
